<template>
  <div class="form-group">
    <label class="form-group__container">
      <span
        v-if="label"
        :class="['form-group__label', disabled ? 'form-group__label_disabled' : '']"
        >{{ label }}</span
      >
      <input
        @input="$emit('input', $event.target.value)"
        :type="widgetType"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :maxlength="maxlength"
        :minlength="minlength"
        :class="['form-group__input', value ? 'form-group__input_complete' : '']"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "Input.vue",
  data() {},
  props: {
    label: {
      type: String
    },
    widgetType: {
      type: String,
      default: "text"
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    maxlength: {
      type: String
    },
    minlength: {
      type: String
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";

@mixin input-box-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.03);
}

.form-group {
  &__container {
    display: flex;
    flex-direction: column;

    color: $color-text-secondary;

    &:hover .form-group__input:not(:disabled) {
      @include input-box-shadow;
    }
  }

  &__label {
    &_disabled {
      color: $color-disabled;
    }
  }

  &__input {
    padding: 7px;

    color: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    border: 1px solid $color-other-2;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;

    &:focus {
      color: $color-text-primary-2;

      @include input-box-shadow;
      border-color: $color-other-1;
    }

    &:disabled {
      color: $color-disabled;

      border-color: $color-disabled;
      background-color: $color-white;
    }

    &::placeholder {
      font-family: inherit;
      color: inherit;
    }

    &_complete {
      color: $color-text-primary-2;

      border-color: $color-other-1;
    }
  }
}
</style>
